import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AOS from 'aos';
import "aos/dist/aos.css";
import React, { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import NotFound from "./pages/NotFound";


function App() {
  useEffect(() => {
    AOS.init({
      // Global settings (optional)
      duration: 800, // values from 0 to 3000, with step 50ms
    });
    setTimeout(() => {
      console.clear();
      console.log(`    

         ██████╗ ██████╗  █████╗ ███╗   ██╗     ██╗ █████╗ ██╗           ██╗  ██╗██╗   ██╗███╗   ███╗ █████╗ ██████╗ 
         ██╔══██╗██╔══██╗██╔══██╗████╗  ██║     ██║██╔══██╗██║           ██║ ██╔╝██║   ██║████╗ ████║██╔══██╗██╔══██╗
         ██████╔╝██████╔╝███████║██╔██╗ ██║     ██║███████║██║     █████╗█████╔╝ ██║   ██║██╔████╔██║███████║██████╔╝
         ██╔═══╝ ██╔══██╗██╔══██║██║╚██╗██║██   ██║██╔══██║██║     ╚════╝██╔═██╗ ██║   ██║██║╚██╔╝██║██╔══██║██╔══██╗
         ██║     ██║  ██║██║  ██║██║ ╚████║╚█████╔╝██║  ██║███████╗      ██║  ██╗╚██████╔╝██║ ╚═╝ ██║██║  ██║██║  ██║
         ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚════╝ ╚═╝  ╚═╝╚══════╝      ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝
                                                                                                                     
        
   `);
   console.log(`This website was developed by Pranjal Kumar \nVisit below link to know more: https://pranjalkumar.in`);

    }, 2000);
  }, []);

  return (
    <>

      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </>
  )
}

export default App;
