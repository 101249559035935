import React from 'react'

const ProductItems = () => {
    const products = [
        {
            "letter": "A",
            "chemicals": [
                {
                    "name": "Aluminium Chloride - Grn/Pwd",
                    "description": "Used in chemical synthesis, catalyst in reactions."
                },
                {
                    "name": "Ammonium Bicarbonate - Powder",
                    "description": "Leavening agent in food, used in baking."
                },
                {
                    "name": "Acetonitrile",
                    "description": "Solvent for organic synthesis, HPLC applications."
                },
                {
                    "name": "Acetic Acid",
                    "description": "Main component of vinegar, used in food."
                },
                {
                    "name": "Acetone",
                    "description": "Widely used solvent, cleaning agent in industries."
                },
                {
                    "name": "Acrylamide Crystal",
                    "description": "Polymer precursor, used in water treatment."
                },
                {
                    "name": "Acrylic Acid",
                    "description": "Used in making paints, coatings, adhesives."
                },
                {
                    "name": "Adipic Acid",
                    "description": "Used in nylon production, food additives."
                },
                {
                    "name": "Ammonium Chloride Tech / Pure Grade",
                    "description": "Used in fertilizers, metalwork, and textiles."
                },
                {
                    "name": "Ammonium Thiocyanate",
                    "description": "Used in photography, chemical synthesis."
                },
                {
                    "name": "Aniline",
                    "description": "Precursor in polyurethane, rubber industry."
                }
            ]
        },
        {
            "letter": "B",
            "chemicals": [
                {
                    "name": "Benzyl Alcohol",
                    "description": "Solvent, preservative, and fragrance in cosmetics."
                },
                {
                    "name": "Benzyl Chloride",
                    "description": "Used in organic synthesis for benzyl group introduction."
                },
                {
                    "name": "Benzaldehyde",
                    "description": "Almond-like aroma, used in flavors and fragrances."
                },
                {
                    "name": "Bleaching Powder",
                    "description": "Disinfectant and bleaching agent in sanitation."
                },
                {
                    "name": "Benzoic Acid",
                    "description": "Preservative in food, pharmaceuticals, cosmetics."
                },
                {
                    "name": "Bisphenol A",
                    "description": "Used in plastics, epoxy resins, polycarbonate production."
                },
                {
                    "name": "Borax Decahydrate Crystal / Granular",
                    "description": "Used in detergents, cosmetics, glass production."
                },
                {
                    "name": "Borax Pentahydrate",
                    "description": "Component of detergents, glass, ceramics."
                },
                {
                    "name": "Butyl Acrylate Monomer",
                    "description": "Used in coatings, adhesives, and sealants."
                },
                {
                    "name": "Butyl Carbitol (Butyl Diglycol)",
                    "description": "Solvent for coatings, inks, and cleaners."
                },
                {
                    "name": "Butyl Carbitol Acetate (Butyl Diglycol Acetate)",
                    "description": "Slow-evaporating solvent used in coatings, inks."
                },
                {
                    "name": "Butyl Carbitol - Eastman DB",
                    "description": "Solvent for resins, coatings, and printing inks."
                },
                {
                    "name": "Butyl Cellosolve Acetate (Butyl Glycol Acetate)",
                    "description": "Used as solvent in lacquers, coatings, cleaning agents."
                },
                {
                    "name": "Butyl Cellosolve (Butyl Glycol)",
                    "description": "Industrial solvent for paints, varnishes, cleaning solutions."
                }
            ]
        },
        {
            "letter": "C",
            "chemicals": [
                {
                    "name": "Calcium Chloride - Powder",
                    "description": "De-icing, dust control, and concrete additive."
                },
                {
                    "name": "Caustic Potash - Flakes/Lye",
                    "description": "Strong base, used in soaps and detergents."
                },
                {
                    "name": "Caustic Soda- Flakes/Lye/Prills",
                    "description": "Alkaline substance, used in chemical manufacturing."
                },
                {
                    "name": "Chloroform",
                    "description": "Former anesthetic, now used as a solvent."
                },
                {
                    "name": "Cyclohexane",
                    "description": "Solvent, used in the production of nylon."
                },
                {
                    "name": "Cyclohexanone",
                    "description": "Intermediate for nylon, solvent for resins."
                },
                {
                    "name": "Cyclohexylamine",
                    "description": "Used in rubber chemicals, corrosion inhibitors."
                }
            ]
        },
        {
            "letter": "D",
            "chemicals": [
                {
                    "name": "Di Methyl Amine 40%",
                    "description": "Used in pharmaceuticals, pesticides, and solvents."
                },
                {
                    "name": "Di Ethyl Amine",
                    "description": "Intermediate in organic synthesis, used in drugs."
                },
                {
                    "name": "DCDA",
                    "description": "Used in resins, textiles, explosives."
                },
                {
                    "name": "Di Cylohexylamin",
                    "description": "Used in corrosion inhibitors, rubber processing."
                },
                {
                    "name": "Di Ethylene Triamine (DETA)",
                    "description": "Intermediate in resins, fuel additives, and chemicals."
                },
                {
                    "name": "Di Iso Propyl Ether (DIPE)",
                    "description": "Solvent, used in oil refineries and chemical industries."
                },
                {
                    "name": "Di Methyl Acetaminde (DMAC)",
                    "description": "Solvent in pharmaceuticals and synthetic fibers."
                },
                {
                    "name": "Di Methyl Amino Ethanol (DMAE)",
                    "description": "Used in personal care products, drug intermediates."
                },
                {
                    "name": "Di Methyl Formamide (DMF)",
                    "description": "Solvent used in synthetic fibers, plastics."
                },
                {
                    "name": "Di Methyl Sulfoxide (DMSO)",
                    "description": "Pharmaceutical solvent with anti-inflammatory properties."
                },
                {
                    "name": "Dimethyl Melonate",
                    "description": "Used in pharmaceuticals and pesticides synthesis."
                },
                {
                    "name": "Di-N-Butyl-Amine",
                    "description": "Intermediate for agrochemicals, rubber additives."
                },
                {
                    "name": "DMC-Korea / China",
                    "description": "Dimethyl carbonate, used in solvents, battery electrolytes."
                }
            ]
        },
        {
            "letter": "E",
            "chemicals": [
                {
                    "name": "Ethyl Acetate",
                    "description": "Common solvent used in glues, nail polish."
                },
                {
                    "name": "Epichlorohydrin",
                    "description": "Used in resins, plastics, and adhesives."
                },
                {
                    "name": "Ethyl Aceto Acetate",
                    "description": "Used in paints, coatings, and plasticizers."
                },
                {
                    "name": "Ethyl Acrylate Monomer",
                    "description": "Used in plastics, adhesives, coatings."
                },
                {
                    "name": "Ethyl Chloro Formate",
                    "description": "Intermediate in pharmaceuticals, agrochemicals."
                },
                {
                    "name": "Ethylene Diamine (EDA)",
                    "description": "Intermediate for resins, lubricants, and detergents."
                }
            ]
        },
        {
            "letter": "F",
            "chemicals": [
                {
                    "name": "Formamide",
                    "description": "Used in paper, pharmaceuticals, plastics production."
                },
                {
                    "name": "Formic Acid 85% / 99%",
                    "description": "Used in leather tanning, textiles, and preservatives."
                }
            ]
        },
        {
            "letter": "G",
            "chemicals": [
                {
                    "name": "Glyoxal 40%",
                    "description": "Used in textiles, paper coatings, disinfection."
                }
            ]
        },
        {
            "letter": "H",
            "chemicals": [
                {
                    "name": "Hydrochloric Acid",
                    "description": "Used in metal processing, pH control, chemicals."
                },
                {
                    "name": "Hydrogen Peroxide 35%",
                    "description": "Bleaching agent in paper and textiles."
                },
                {
                    "name": "Hydrogen Peroxide 50%",
                    "description": "Disinfectant, bleaching, and industrial oxidizer."
                },
                {
                    "name": "Hydroxylamine Sulphate (HAS)",
                    "description": "Used in pharmaceuticals, rubber, photography."
                },
                {
                    "name": "Hexamine",
                    "description": "Fuel tablets, used in explosives and chemicals."
                },
                {
                    "name": "Hydroquinone",
                    "description": "Skin-lightening agent, used in photography."
                },
                {
                    "name": "Hydroxylamine Hydrochloride (HAHCL)",
                    "description": "Used in organic synthesis and as a reducing agent."
                }
            ]
        },
        {
            "letter": "L",
            "chemicals": [
                {
                    "name": "L+ Tartaric Acid",
                    "description": "Used in winemaking, food, and pharmaceuticals."
                }
            ]
        },
        {
            "letter": "M",
            "chemicals": [
                {
                    "name": "Methylene Chloride",
                    "description": "Solvent for paint strippers, degreasers, and adhesives."
                },
                {
                    "name": "Mono Methyl Amine 40%",
                    "description": "Used in pharmaceuticals, agriculture, and resin production."
                },
                {
                    "name": "Maleic Anhydride",
                    "description": "Intermediate in the production of resins and plastics."
                },
                {
                    "name": "Melamine",
                    "description": "Used in laminates, adhesives, and plastics."
                },
                {
                    "name": "Methacrylic Acid",
                    "description": "Used in the production of acrylic resins and polymers."
                },
                {
                    "name": "Methane Sulphonic Acid (MSA)",
                    "description": "Used as a catalyst in chemical reactions and synthesis."
                },
                {
                    "name": "Methane Sulphonyl Chloride (MSC)",
                    "description": "Used in the synthesis of pharmaceuticals and agrochemicals."
                },
                {
                    "name": "Methanol",
                    "description": "Used as a solvent, antifreeze, and fuel."
                },
                {
                    "name": "Methyl Aceto Acetate",
                    "description": "Used in organic synthesis, pharmaceuticals, and fragrances."
                },
                {
                    "name": "Methyl Acrylate Monomer",
                    "description": "Used in coatings, adhesives, and polymers."
                },
                {
                    "name": "Methyl Cyclohexane",
                    "description": "Used as a solvent and in petrochemical processes."
                },
                {
                    "name": "Methyl Chloro Formate (MCF)",
                    "description": "Intermediate in the synthesis of pharmaceuticals and chemicals."
                },
                {
                    "name": "Methly Iso Butyl Ketone (MIBK)",
                    "description": "Solvent used in coatings and chemical processes."
                },
                {
                    "name": "Mono-N-Butyl-Amine",
                    "description": "Used in pesticides, rubber chemicals, and pharmaceuticals."
                },
                {
                    "name": "Methy Methacrylate Monomer",
                    "description": "Used in acrylic glass and polymer production."
                },
                {
                    "name": "Methyl Tertiary Butyl Ether (MTBE)",
                    "description": "Used as a fuel additive to improve octane ratings."
                },
                {
                    "name": "Mono Iso Propyl Amine (MIPA) - 70% / 99%",
                    "description": "Used in agrochemicals, surfactants, and solvents."
                },
                {
                    "name": "Monoethanolamine",
                    "description": "Used in detergents, cosmetics, and pharmaceuticals."
                },
                {
                    "name": "Morpholine",
                    "description": "Used in rubber processing and as a solvent."
                },
                {
                    "name": "MP Diol",
                    "description": "Used in the production of polymers and surfactants."
                }
            ]
        },
        {
            "letter": "N",
            "chemicals": [
                {
                    "name": "N - Heptane",
                    "description": "Used as a solvent and in fuel formulations."
                },
                {
                    "name": "N - Methyl Piperazine",
                    "description": "Used in pharmaceuticals, agrochemicals, and synthesis."
                },
                {
                    "name": "N - Propanol",
                    "description": "Used as a solvent and in chemical synthesis."
                },
                {
                    "name": "N - Propyl Acetate",
                    "description": "Used as a solvent in paints and coatings."
                },
                {
                    "name": "N Butyl Acetate",
                    "description": "Used as a solvent in paints and coatings."
                },
                {
                    "name": "N - Butyl Methacrylate",
                    "description": "Used in adhesives, paints, and polymer production."
                },
                {
                    "name": "N Hexane - 99%",
                    "description": "Used as a solvent in extraction and refining."
                },
                {
                    "name": "N - Methyl - 2 - Pyrrolidone (NMP)",
                    "description": "Used as a solvent in pharmaceuticals and polymers."
                },
                {
                    "name": "Neo Pentyl Glycol",
                    "description": "Used in resins, plasticizers, and coatings."
                },
                {
                    "name": "Nonyl Phenol",
                    "description": "Used in surfactants, plasticizers, and resins."
                },
                {
                    "name": "Nonyl Phenol Ethoxylate",
                    "description": "Nonionic surfactant used in detergents and emulsifiers."
                }
            ]
        },
        {
            "letter": "P",
            "chemicals": [
                {
                    "name": "Phosphoric Acid 85%",
                    "description": "Used in fertilizers, detergents, and food processing."
                },
                {
                    "name": "Poly Aluminium Chloride (PAC)",
                    "description": "Used in water treatment and purification processes."
                },
                {
                    "name": "Potassium Carbonate Grn/Pwd",
                    "description": "Used in fertilizers and as a buffering agent."
                },
                {
                    "name": "Para Tertiary Butylphenol (PTBP)",
                    "description": "Used in resins and as a surfactant."
                },
                {
                    "name": "Paraformaldehyde 91% / 96%",
                    "description": "Used in adhesives, coatings, and preservation."
                },
                {
                    "name": "Pentaerythritol",
                    "description": "Used in explosives, resins, and lubricants."
                },
                {
                    "name": "PG Tech - Technical Grade / USP Grade",
                    "description": "Used in pharmaceuticals and as a carrier solvent."
                },
                {
                    "name": "Phenol",
                    "description": "Used in plastics, resins, and antiseptics."
                },
                {
                    "name": "Phthalic Anhydride",
                    "description": "Used in plastics, dyes, and coatings."
                },
                {
                    "name": "Piperazine Anhydrous",
                    "description": "Used in pharmaceuticals and chemical synthesis."
                },
                {
                    "name": "Propionic Acid",
                    "description": "Used as a preservative and in food processing."
                },
                {
                    "name": "Propyl Glycol Mono Methyl Ether Propylene Glycol Mono M.E. Acetate",
                    "description": "Used as a solvent in coatings and cleaning products."
                },
                {
                    "name": "Propylene Glycol",
                    "description": "Used in food, pharmaceuticals, and cosmetics."
                },
                {
                    "name": "Pyrrolidone",
                    "description": "Used as a solvent in pharmaceuticals and cosmetics."
                }
            ]
        },
        {
            "letter": "R",
            "chemicals": [
                {
                    "name": "Refined Glycerine",
                    "description": "Used in food, pharmaceuticals, and cosmetics."
                }
            ]
        },
        {
            "letter": "S",
            "chemicals": [
                {
                    "name": "Sodium Nitrite",
                    "description": "Used as a preservative and in curing meats."
                },
                {
                    "name": "Sodium Nitrate",
                    "description": "Used in fertilizers and food preservation."
                },
                {
                    "name": "Soda Ash",
                    "description": "Used in glass production and as a cleaning agent."
                },
                {
                    "name": "Sodium Borohydride Powder",
                    "description": "Used as a reducing agent in chemical reactions."
                },
                {
                    "name": "Sodium Gluconate",
                    "description": "Used as a food additive and cleaning agent."
                },
                {
                    "name": "Sodium Metal",
                    "description": "Used in chemical synthesis and as a reducing agent."
                },
                {
                    "name": "Sodium Methoxide Powder",
                    "description": "Used as a catalyst in organic synthesis."
                }
            ]
        },
        {
            "letter": "T",
            "chemicals": [
                {
                    "name": "Tri Ethylamine",
                    "description": "Used as a solvent and in organic synthesis."
                },
                {
                    "name": "Toluene Diisocyanate",
                    "description": "Used in polyurethane production and adhesives."
                },
                {
                    "name": "Technical Grade Urea",
                    "description": "Used in fertilizers and in chemical synthesis."
                },
                {
                    "name": "Tertiary Butanol (TBA)",
                    "description": "Used as a solvent and in fuel applications."
                },
                {
                    "name": "Tert-Butyl-Amine",
                    "description": "Used in organic synthesis and pharmaceutical production."
                },
                {
                    "name": "Tetra Hydro Furan (THF)",
                    "description": "Used as a solvent for polymers and resins."
                },
                {
                    "name": "Thio Urea",
                    "description": "Used in agriculture and as a chemical intermediate."
                },
                {
                    "name": "Toluene",
                    "description": "Used as a solvent in paint and coatings."
                },
                {
                    "name": "Tri Ethyl Ortho Formate (TEOF)",
                    "description": "Used in organic synthesis and chemical reactions."
                },
                {
                    "name": "Tri Methylol Propane",
                    "description": "Used in resins, coatings, and adhesives."
                },
                {
                    "name": "Triethanolamine 85% / 99%",
                    "description": "Used in personal care products and as a surfactant."
                },
                {
                    "name": "Tri Chloroethylene",
                    "description": "Used as a solvent in industrial cleaning."
                }
            ]
        },
        {
            "letter": "#",
            "chemicals": [
                {
                    "name": "2 Ethyl Hexanoic Acid (Octoic Acid)",
                    "description": "Used as a lubricant and in coatings."
                },
                {
                    "name": "2 Ethyl Hexyl Acrylate",
                    "description": "Used in coatings and adhesives."
                },
                {
                    "name": "2 HEMA",
                    "description": "Used in polymer synthesis and cosmetics."
                }
            ]
        }

    ]



    return (
        <div className='mb-20 relative pb-52' id='products'>
            
            {products.map(table => ProductItemTable(table))}

            <div className="fixed top-44 right-10 hidden md:block" data-aos="zoom-out-up" data-aos-delay='600'>
                <ul className="menu rounded-2xl backdrop-blur-sm bg-gray-200 shadow-lg px-2 py-2 text-center">
                    {products.map(table =>
                        <a href={`/products#${table.letter}`} className="tooltip tooltip-right" data-tip={table.letter}>
                            <li className=' font-bold px-2 mb-1 text-gray-500 hover:text-white cursor-pointer rounded-full hover:bg-sky-500'>
                                {table.letter}
                            </li>
                        </a>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default ProductItems


const ProductItemTable = (table: any) => {
    return (
        <div className="max-w-screen-xl mx-auto px-4 md:px-8 pt-24" id={table.letter}>
            <div className="items-start justify-between md:flex">
                <div className="max-w-lg ml-6">
                    <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                        {table.letter}
                    </h3>
                    <p className="text-gray-600 mt-2">
                        Chemical compounds starting with '{table.letter}'
                    </p>
                </div>
            </div>
            <div className="mt-4 shadow-sm border rounded-lg overflow-x-auto">
                <table className="w-full table-auto text-sm text-left">
                    <thead className="bg-gray-400 text-gray-50 font-medium border-b">
                        <tr>
                            <th className="py-3 px-6">Compound</th>
                            <th className="py-3 px-6">Description</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 divide-y">
                        {
                            table.chemicals.map((chemical: any, idx: number) => (
                                <tr key={idx} className={idx % 2 === 1 ? 'bg-gray-100' : 'bg-white'}>
                                    <td className="px-6 py-4 whitespace-wrap font-medium text-gray-500">{chemical.name}</td>
                                    <td className="px-6 py-4 whitespace-wrap text-gray-700">{chemical.description}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}