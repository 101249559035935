
import React from "react"
import FaqItem from './FaqItem'

interface FaqItem {
    q: string;
    a: string;
}

const Faq: React.FC = () => {
    const faqsList: FaqItem[] = [
        {
            q: "What types of chemicals do we trade?",
            a: "We offer a wide range of chemicals, including those for food and flavors manufacturing, pharmaceuticals, textiles, and more."
        },
        {
            q: "Do you provide customized solutions?",
            a: "Yes, we offer tailored chemical solutions to meet specific client needs."
        },
        {
            q: "How can I place an order?",
            a: "You can place an order by contacting us via email, phone, or through our contact form. \nPhone: +91 9175-102-140 \nEmail: info@rktradchem.com"
        }
    ]

    return (
        <div className="relative leading-relaxed max-w-screen-xl my-36 mx-auto px-4 md:px-8">
            {/* Gradient Background */}
            {/* <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
                <div
                    className="relative left-1/3 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3fccff] to-[#6459ff] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                ></div>

            </div> */}
            
            {/* Headers */}
            <div className="space-y-3 text-center" id="faq">
                <h1 className="text-4xl text-gray-600 font-bold">
                    Frequently Asked Questions
                </h1>
                <p className="text-gray-600 max-w-lg mx-auto text-lg">
                    Answered all frequently asked questions, still confused? 
                    <br />
                    Feel free to contact us.
                </p>
            </div>
            
            {/* FAQ Question */}
            <div className="mt-10 max-w-2xl mx-auto" data-aos="zoom-out-up">
                {faqsList.map((item, idx) => (
                    <FaqItem
                        key={idx}
                        faqsList={item}
                        idx={idx}
                    />
                ))}
            </div>
        </div>
    )
}

export default Faq