import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [state, setState] = useState(false)
    const navigate = useNavigate();

    const navigation = [
        { title: "About", path: "/", scrollTo:"ceonote" },
        { title: "Products", path: "/products", scrollTo:"" },
        { title: "Industries", path: "/", scrollTo:"industries" },
        { title: "Contact", path: "/", scrollTo:"contact" },
    ]

    const handleNavigation = (path:string, scrollTo:string) => {
        // Navigate to another page
        navigate(path);

        setTimeout(() => {
            const element = document.getElementById(scrollTo);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 200);
    };
    
    return (
        <nav className="relative z-50 items-center pt-5 px-4 mx-auto max-w-screen-xl sm:px-8 md:flex md:space-x-6">
            <div className="flex justify-between">
                <a href="/">
                    <img
                        src="wide-logo.png"
                        width={140}
                        // height={80}
                        alt="Float UI logo"
                    />
                </a>
                <button className="text-gray-500 outline-none md:hidden"
                    onClick={() => setState(!state)}
                >
                    {
                        state ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        ) : (

                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        )
                    }
                </button>
            </div>
            <ul className={`flex-1 justify-between md:text-sm md:font-medium md:flex md:mt-0 ${state ? 'absolute inset-x-0 px-4 border-b bg-white md:border-none md:static' : 'hidden'}`}>
                <div className="items-center space-y-5 md:flex md:space-x-6 md:space-y-0 md:ml-12">
                    {
                        navigation.map((item, idx) => (
                            <li className="text-gray-500 hover:text-sky-700" key={idx}>
                                <a className='cursor-pointer' onClick={() => handleNavigation(item.path, item.scrollTo)}>{item.title}</a>
                            </li>
                        ))
                    }
                </div>
                <li className="order-2 py-5 md:py-0">
                </li>
                <li className="order-3 md:py-0">
                    <div className="flex">
                        <a className='mr-4' target="_blank" href="https://wa.me/+919175102140?text=Hi%20R%20K%20Trading%20Team,%0AI'm%20interested%20for%20a%20product%20and%20would%20like%20to%20enquire%20about%20it.">
                            <img src="whatsapp.png" alt="" className='block h-12' />
                        </a>
                        <a href="/products" rel="noreferrer" className="py-2 px-5 rounded-lg font-medium text-sky-700 text-center border-2 border-sky-700 bg-transparent hover:bg-sky-700 hover:text-white active:bg-sky-600 duration-150 block md:py-3    ">
                            Get started
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar