import React from 'react'

const Qualities = () => {
    const qualities = [
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 2v10m0 0l-5-5m5 5l5-5M2 12h10m0 0l-5 5m5-5l5 5" />
                </svg>,
            title: "Diverse Chemical Portfolio",
            desc: "We offer an extensive range of chemicals for various industries, including food and flavors manufacturing, pharmaceuticals, textiles, and more."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 3h12l3 9-3 9H6l-3-9 3-9z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 3v6h12V3m-9 0h3" />
                </svg>,
            title: "Global Import and Export",
            desc: "Leveraging a robust international network, we ensure timely and compliant delivery of chemicals worldwide."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 6h9a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3zM9 2h6a1 1 0 0 1 1 1v1H8V3a1 1 0 0 1 1-1zM8 4h8v1H8V4z" />
                </svg>,
            title: "Customized Chemical Solutions",
            desc: "We provide bespoke solutions to meet the specific needs of our clients, enhancing their operational efficiency."
        }
    ]

    return (
        <div className="mt-12 px-10 md:px-40 mb-20">
            <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
                {
                    qualities.map((item, idx) => (
                        <li key={idx} className="flex gap-x-4"  data-aos="zoom-out-up" data-aos-delay={150 * idx}>
                            <div className="flex-none w-12 h-12 bg-sky-100 text-sky-700 rounded-lg flex items-center justify-center">
                                {item.icon}
                            </div>
                            <div>
                                <h4 className="text-lg text-gray-800 font-semibold">
                                    {item.title}
                                </h4>
                                <p className="mt-1 text-sm">
                                    {item.desc}
                                </p>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Qualities